/**
 * 获取本周、本季度、本月、上月的开端日期、停止日期
 */
const now = new Date() // 当前日期
const nowDayOfWeek = now.getDay() // 今天本周的第几天
const nowDay = now.getDate() // 当前日
const nowMonth = now.getMonth() // 当前月
let nowYear = now.getYear() // 当前年
nowYear += nowYear < 2000 ? 1900 : 0

// 获得某月的天数
function getMonthDays(myMonth) {
  const monthStartDate = new Date(nowYear, myMonth, 1)
  const monthEndDate = new Date(nowYear, myMonth + 1, 1)
  const days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24)
  return days
}

// 获得本季度的开端月份
function getQuarterStartMonth() {
  let quarterStartMonth = 0
  if (nowMonth < 3) {
    quarterStartMonth = 0
  }
  if (nowMonth > 2 && nowMonth < 6) {
    quarterStartMonth = 3
  }
  if (nowMonth > 5 && nowMonth < 9) {
    quarterStartMonth = 6
  }
  if (nowMonth > 8) {
    quarterStartMonth = 9
  }
  return quarterStartMonth
}

// 格局化日期：yyyy-MM-dd
function formatDate(date) {
  const myyear = date.getFullYear()
  let mymonth = date.getMonth() + 1
  let myweekday = date.getDate()

  if (mymonth < 10) {
    mymonth = '0' + mymonth
  }
  if (myweekday < 10) {
    myweekday = '0' + myweekday
  }
  return myyear + '-' + mymonth + '-' + myweekday
}
// 获得本周的开端日期
export function getWeekStartDate() {
  const millisecond = 1000 * 60 * 60 * 24
  const minusDay = nowDayOfWeek !== 0 ? nowDayOfWeek - 1 : 6
  const weekStartDate = new Date(new Date().getTime() - minusDay * millisecond)
  return formatDate(weekStartDate)
}

// 获得本周的停止日期
export function getWeekEndDate() {
  const weekEndDate = new Date(nowYear, nowMonth, nowDay + (7 - nowDayOfWeek))
  return formatDate(weekEndDate)
}

// 获得本月的开端日期
export function getMonthStartDate() {
  const monthStartDate = new Date(nowYear, nowMonth, 1)
  return formatDate(monthStartDate)
}

// 获得本月的停止日期
export function getMonthEndDate() {
  const monthEndDate = new Date(nowYear, nowMonth, getMonthDays(nowMonth))
  return formatDate(monthEndDate)
}
// 获得上月的停止日期
export function getLastMonthEndDate() {
  let monthEndDate
  if (nowMonth !== 0) {
    monthEndDate = new Date(nowYear, nowMonth - 1, getMonthDays(nowMonth - 1))
    return formatDate(monthEndDate)
  }
  monthEndDate = new Date(nowYear - 1, 11, getMonthDays(nowMonth - 1))
  return formatDate(monthEndDate)
}
// 获得本季度的开端日期
export function getQuarterStartDate() {
  const quarterStartDate = new Date(nowYear, getQuarterStartMonth(), 1)
  return formatDate(quarterStartDate)
}

// 获得本季度的停止日期
export function getQuarterEndDate() {
  const quarterEndMonth = getQuarterStartMonth() + 2
  const quarterStartDate = new Date(
    nowYear,
    quarterEndMonth,
    getMonthDays(quarterEndMonth)
  )
  return formatDate(quarterStartDate)
}

// 获取本年开始日期
export function getYearStartDate() {
  return `${new Date().getFullYear()}-01-01`
}

// 获取本年结束日期
export function getYearEndDate() {
  return `${new Date().getFullYear()}-12-31`
}

// 获取上个月当前时间
export function getLastMonthNowDate() {
  const date = new Date()
  const lastMonth = date.getMonth() + 1
  if (lastMonth > 11) {
    date.setFullYear(date.getFullYear() + 1)
    date.setMonth(0)
  } else {
    date.setMonth(lastMonth)
  }
  return date
}
