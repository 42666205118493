import Loadable from 'react-loadable'
import _ from 'lodash'
import { message } from 'antd'
import Loading from '@components/base/Loading'
import qs from 'qs'
import { FORM_TYPE, CUSTOM_FORM_REQUIRED, Loginpage } from './constant'

export const checkPermission = (pathname, permissionRoutes = []) => {
  return !!permissionRoutes.find((route) => route === pathname)
}

export const getUrlDenyListByRole = (roleMenu = [], role = 1) => {
  const urlDenyList = []
  roleMenu.forEach((data) => {
    if (data.role > role) {
      urlDenyList.push(data.value)
    } else if (data.filterGroups) {
      const parentPath = data.value
      data.filterGroups.forEach((group) => {
        group.filters.forEach((filter) => {
          if (filter.role > role) {
            urlDenyList.push(`${parentPath}/${filter.value}`)
          }
        })
      })
    }
  })
  return urlDenyList
}

// 异步加载组件
export const getAsyncComponent = (loader, loadingCom = Loading) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  Loadable({
    loader,
    loading: loadingCom,
  })

export const validateCustomForm = (jsonArr) => {
  const errors = []
  const treeNodeVals = []
  // 校验必填
  const checkRequire = (json, idx) => {
    const param = CUSTOM_FORM_REQUIRED[json.type].find((v) => {
      if (json[v] instanceof Array) {
        return json[v].length === 0
      }
      if (json[v] instanceof Object) {
        return Object.keys(json[v]).length === 0
      }
      return !json[v]
    })
    if (param) {
      errors.push({
        path: [idx],
        message: '参数' + param + '不能为空',
      })
      return false
    }
    return true
  }

  // 校验值重复
  const checkRepeat = (checkArr, paramName, idx) => {
    if (checkArr.length !== [...new Set(checkArr)].length) {
      errors.push({
        path: [idx, paramName],
        message: paramName + '中不应存在value值相同的两个元素',
      })
    }
  }

  const collectTreeNodeVals = (nodes) => {
    const __collectTreeNodeVals = (node) => {
      treeNodeVals.push(node.value)
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => __collectTreeNodeVals(child))
      }
    }
    nodes.forEach((node) => {
      __collectTreeNodeVals(node)
    })
  }

  jsonArr.forEach((json, idx) => {
    switch (json.type) {
      case FORM_TYPE.SELECT:
        if (checkRequire(json, idx)) {
          checkRepeat(Object.values(json.selects), 'selects', idx)
        }
        break
      case FORM_TYPE.TREE:
        if (checkRequire(json, idx)) {
          collectTreeNodeVals(json.nodes)
          checkRepeat(treeNodeVals, 'nodes', idx)
        }
        break
      default:
        break
    }
  })
  return errors
}

export const checkResponse = (res) => {
  return new Promise((resolve, reject) => {
    if (res.status >= 200 && res.status < 300) {
      resolve(res.data)
    } else {
      console.log(res.statusText)
      reject(res.statusText)
    }
  })
}

export const getNoNullObj = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] instanceof Object) {
      getNoNullObj(obj[key])
    }
    if (!obj[key]) {
      delete obj[key]
    }
  })
  return obj
}

export const getMergeObj = (obj) => {
  const newObj = {}
  Object.keys(obj).forEach((key) => {
    if (key.indexOf('.') > -1) {
      _.set(newObj, key, obj[key])
    } else {
      newObj[key] = obj[key]
    }
  })
  return newObj
}

export const getProjectId = () => {
  const id = window.location.pathname.split('/')[2]
  return id
}

export const GetRequest = (queryName) => {
  const query = decodeURI(window.location.search.substring(1))
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0] === queryName) {
      return pair[1]
    }
  }
  return null
}

export const filterTree = (tree, valueAry) => {
  return tree.filter(function iter(node) {
    let temp = []
    if (node.label.indexOf(valueAry) !== -1) {
      return true
    }
    if (!Array.isArray(node.children) || node.children.length === 0) {
      return false
    }
    temp = node.children.filter(iter)
    if (temp.length) {
      node.children = temp
      return true
    }
    return false
  })
}
export const filterTreeByTitle = (tree, valueAry) => {
  return tree.filter(function iter(node) {
    let temp = []
    if (node.title.indexOf(valueAry) !== -1) {
      return true
    }
    if (!Array.isArray(node.children) || node.children.length === 0) {
      return false
    }
    temp = node.children.filter(iter)
    if (temp.length) {
      node.children = temp
      return true
    }
    return false
  })
}
export const filterStandardTree = (tree) => {
  return tree.filter(function iter(node) {
    let temp = []
    if (!node.isUsed) {
      return true
    }
    if (!Array.isArray(node.children) || node.children.length === 0) {
      return false
    }
    temp = node.children.filter(iter)
    if (temp.length) {
      node.children = temp
      return true
    }
    return false
  })
}

/**
 *
 * @param {string} type [success, error, warning]
 * @param {*} msg
 */
export const showMessage = (type, msg) => {
  message[type](msg)
}

//  获取当前年月'2020-07'
export const getCurrentMonth = () => {
  const myDate = new Date()
  const tMonth = myDate.getMonth()
  const tDate = myDate.getDate()
  const tYear = myDate.getFullYear()

  let m = tMonth + 1
  if (m.toString().length === 1) {
    m = '0' + m
  }
  return tYear + '-' + m + '-' + tDate
}
export const setSpecialKeys = (datas) => {
  window.specialSettingkeys = datas
}
export const getSpecialKeys = (key) => {
  if (key) {
    return window.specialSettingkeys ? window.specialSettingkeys[key] || {} : {}
  }
  return window.specialSettingkeys || []
}
export const isNeedLoginPage = () => {
  return (
    window.location.href.indexOf('404') !== -1 ||
    window.location.href.indexOf('login') !== -1 ||
    window.location.href.indexOf('home') !== -1 ||
    window.location.href.indexOf('app') !== -1 ||
    window.location.href.indexOf('caseLog') !== -1 ||
    window.location.href.indexOf('business') !== -1
  )
}
export const isLoginPage = () => {
  return window.location.href.indexOf('login') !== -1
}
export const redirectLogin = () => {
  window.location.href = Loginpage
}

// 判断是否设置水印
export const companyIsShowWatermark = [
  process.env.REACT_APP_JCB_ENV === 'dev' ? '20005' : '1450808',
]

export const getQuery = () => {
  return qs.parse(document.location.search.split('?')[1])
}
