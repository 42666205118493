import React from 'react'
import noRoleImg from '@assets/images/noRole.png'
import { Button } from 'antd'

const ErrorPage = ({ txt, error }) => {
  return (
    <div
      style={{
        width: '80%',
        margin: '100px auto',
        textAlign: 'center',
      }}
    >
      <img src={noRoleImg} alt="error" style={{ width: '600px' }} />
      <p style={{ marginTop: '30px' }}>{txt || '出错了, 请刷新重试'}</p>
      <p>{error}</p>
      <Button
        type="primary"
        style={{ marginTop: '10px' }}
        onClick={() => {
          window.location.reload()
        }}
      >
        刷新
      </Button>
    </div>
  )
}

export default ErrorPage
