import React from 'react';
import { Spin } from 'antd';
import styles from './Loading.module.scss';

const Loading = () => (
  <div className={styles.loading}>
    <Spin className={styles.spin} />
  </div>
)

export default Loading;
