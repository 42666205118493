import axios from 'axios'
import { getToken } from 'jcb-token-kit'

axios.interceptors.request.use(
  (config) => {
    const token = getToken()

    if (config.params && config.params.noToken) {
      config.params = {
        ...config.params,
      }
      config.headers = {
        ...config.headers,
      }
    } else if (token) {
      config.params = {
        ...config.params,
        token,
      }
      config.headers = {
        ...config.headers,
        token,
      }
    }

    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)
